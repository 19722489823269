import React from "react";
import { MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { useFormik } from "formik";
import { FormModalWrapper } from "../../components/FormModalWrapper";

const AdminCreateDealModal = ({ onClose, isOpen, onSuccessSubmit }) => {
  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      try {
        // Handle the submission
        handleCloseModal();
        await onSuccessSubmit();
      } catch (error) {}
    },
  });

  return (
    <FormModalWrapper
      title="Create Deal"
      isOpen={isOpen}
      isLoading={formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBInput
            id="key_name"
            label="Name"
            // {...formik.getFieldProps("key_name")}
          />
          {formik.errors.key_name ? (
            <div className="text-danger">{formik.errors.key_name}</div>
          ) : null}
        </MDBCol>
        <MDBCol md={6}>
          <MDBInput
            id="key_path"
            label="Type"
            // {...formik.getFieldProps("key_path")}
          />
          {formik.errors.key_path ? (
            <div className="text-danger">{formik.errors.key_path}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
    </FormModalWrapper>
  );
};

export default AdminCreateDealModal;
