import React from "react";
import { MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { useFormik } from "formik";
import { FormModalWrapper } from "../../components/FormModalWrapper";

const AdminEditDealModal = ({ onClose, itemId, onSuccessSubmit }) => {
  const isOpen = !!itemId;

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      try {
        // Handle the submission
        handleCloseModal();
        await onSuccessSubmit();
      } catch (error) {}
    },
  });

  return (
    <FormModalWrapper
      title="Edit Deal"
      isOpen={isOpen}
      isLoading={formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBInput id="key_name" label="Name" />
          {formik.errors.key_name ? (
            <div className="text-danger">{formik.errors.key_name}</div>
          ) : null}
        </MDBCol>
        <MDBCol md={6}>
          <MDBInput id="key_path" label="Type" />
          {formik.errors.key_path ? (
            <div className="text-danger">{formik.errors.key_path}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
    </FormModalWrapper>
  );
};

export default AdminEditDealModal;
