import ReactSelect from "react-select";

const Select = ({ setSelection, options }) => {
  return (
    <>
      {options.length > 0 && (
        <ReactSelect
          options={options}
          defaultValue={options[0]}
          onChange={(selectedOption) => setSelection(selectedOption.value)}
        />
      )}
    </>
  );
};

export { Select };
