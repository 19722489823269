import React, { useState, useEffect } from "react";
import {
  getArticles,
  getArticleDetails,
  getJournals,
  updateArticleDetails,
} from "../../helpers/articles";
import {
  itemsPerPage,
  allFilterOption,
  dealDefaultFilterOption,
} from "../../constants";
import {
  getWeeklyArticles,
  getWeeklyTransactions,
} from "../../helpers/statistics";
import { MDBCol, MDBRow } from "mdbreact";
import { BarChart, Card, ArticleDetailsModal } from "pubtrack-frontend-library";
import { userSettings } from "../../user-settings";
import { getDeals } from "../../helpers/deals";
import ArticlesTable from "../../components/ArticlesTable";

const Pipeline = () => {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [articleDetails, setArticleDetails] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [weeklyTransactions, setWeeklyTransactions] = useState([]);
  const [weeklyArticles, setWeeklyArticles] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "manuscript ID",
        accessor: "manuscript_id",
        // sortable: true
      },
      {
        Header: "DOI",
        accessor: "doi",
        // sortable: true
      },
      {
        Header: "journal title",
        accessor: "journal",
        // sortable: true
      },
      {
        Header: "manuscript title",
        accessor: "manuscript_title",
        // sortable: true
      },
      {
        Header: "last event",
        accessor: "lastevent",
        // sortable: false
      },
    ],
    []
  );

  const addFilterOption = (arr, key, value) => {
    const isPresent = !!arr.find((item) => item[key] === value);
    if (!isPresent) {
      switch (key) {
        case "title":
          arr.unshift(allFilterOption);
          break;
        case "institution":
          arr.unshift(dealDefaultFilterOption);
          break;
        default:
          break;
      }
    }
    return arr;
  };

  const getArticlesData = ({
    searchValue,
    offset,
    itemsPerPage,
    sortBy,
    sortDir,
    filterValue,
    ror,
  }) => {
    return getArticles(
      searchValue,
      offset,
      itemsPerPage,
      sortBy,
      sortDir,
      "production",
      filterValue,
      ror
    ).then((res) => ({ data: res.data.articles, total: res.data.total }));
  };

  const getFilterData = () => {
    return getJournals().then((res) =>
      addFilterOption(res.data, "title", "All journals")
    );
  };

  const getDealsFilterData = () => {
    return getDeals().then((res) =>
      addFilterOption(res.data, "institution", "N.A.")
    );
  };

  const onSelectRow = (row) => {
    const messageId = row && row.original ? row.original["id"] : null;
    setSelectedMessage(messageId);
  };

  useEffect(() => {
    getWeeklyTransactions().then((res) => setWeeklyTransactions(res.data));

    getWeeklyArticles().then((res) => setWeeklyArticles(res.data));
  }, []);

  useEffect(() => {
    if (selectedMessage) {
      getArticleDetails(selectedMessage)
        .then((res) => setArticleDetails(res.data))
        .then(setIsOpen(true))
        .catch((error) => console.log(error))
        .then(setSelectedMessage(null));
    }
  }, [selectedMessage]);

  const updateArticle = () => {
    return updateArticleDetails(selectedMessage, articleDetails);
  };

  return (
    <div className="container">
      <MDBRow className="mt-5">
        <MDBCol md="6" className="mb-5 mt-4">
          <Card
            title="Transactions per week:"
            content={
              <BarChart
                data={weeklyTransactions}
                xAxisKey="week"
                yAxisKey="count"
                color={"primaryColor"}
              />
            }
          />
        </MDBCol>
        <MDBCol md="6" className="mb-5 mt-4">
          <Card
            title="New articles per week:"
            content={
              <BarChart
                data={weeklyArticles}
                xAxisKey="week"
                yAxisKey="count"
                color={"secondaryColor"}
              />
            }
          />
        </MDBCol>
      </MDBRow>

      <div className="mb-5">
        <ArticlesTable
          columns={columns}
          itemsPerPage={itemsPerPage}
          getTableData={getArticlesData}
          getFilterData={getFilterData}
          onSelectRow={onSelectRow}
        />
      </div>

      <ArticleDetailsModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        articleDetails={articleDetails}
        tabItems={userSettings.details}
        showArticleDeal={true}
        getFilterData={getDealsFilterData}
        updateArticle={updateArticle}
      />
    </div>
  );
};

export default Pipeline;
