import React, { useState } from "react";
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import DataTable from "../../components/DataTable";
import Dialog from "../../components/Dialog";
import AdminEditDealModal from "./AdminEditDealModal";
import AdminCreateDealModal from "./AdminCreateDealModal";

const Deals = () => {
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [itemToEditId, setItemToEditId] = useState(null);
  const [isCreateDealModalOpen, setIsCreateDealModalOpen] = useState(false);

  return (
    <div className="container my-5">
      <Dialog
        onConfirm={() => {}}
        isOpen={!!itemToDeleteId}
        setIsOpen={setItemToDeleteId}
        dialogText={"Are you sure you want to delete this deal?"}
        dialogTitle="Delete deal"
      />

      <AdminEditDealModal
        itemId={itemToEditId}
        onClose={() => setItemToEditId(null)}
        onSuccessSubmit={() => {}}
      />

      <AdminCreateDealModal
        isOpen={isCreateDealModalOpen}
        onClose={() => setIsCreateDealModalOpen(false)}
        onSuccessSubmit={() => {}}
      />
      <MDBRow className="mb-2">
        <MDBCol className="text-end">
          <MDBBtn
            size="md"
            onClick={() => setIsCreateDealModalOpen(true)}
            disabled={false}
          >
            <MDBIcon icon="plus-circle" className="me-2" />
            New deal
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <DataTable
        columns={[
          {
            label: "Name",
            field: "institution",
          },
          {
            label: "Type",
            field: "type",
          },
          {
            label: "Number of articles",
            field: "count",
          },
          {
            field: "editAction",
            label: "",
          },
          {
            field: "deleteAction",
            label: "",
          },
        ]}
        rows={[
          {
            institution: "R&P deal Adeleke University",
            type: "R&P",
            count: 17,
            editAction: (
              <div key={2} onClick={(e) => e.stopPropagation()}>
                <MDBBtn floating onClick={() => setItemToEditId(2)}>
                  <MDBIcon icon="edit" />
                </MDBBtn>
              </div>
            ),
            deleteAction: (
              <div key={1} onClick={(e) => e.stopPropagation()}>
                <MDBBtn
                  color="danger"
                  floating
                  onClick={() => setItemToDeleteId(2)}
                >
                  <MDBIcon icon="trash-alt" />
                </MDBBtn>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Deals;
