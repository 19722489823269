import React, { useState } from "react";
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";

import DataTable from "../../components/DataTable";
import Dialog from "../../components/Dialog";
import AdminEditJournalModal from "./AdminEditJournalModal";
import AdminCreateJournalModal from "./AdminCreateJournalModal";

const AdminJournalsList = () => {
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [itemToEditId, setItemToEditId] = useState(null);
  const [isCreateDealModalOpen, setIsCreateDealModalOpen] = useState(false);

  console.log("item to delete id", itemToDeleteId);
  console.log("item to edit id", itemToEditId);

  return (
    <div className="container my-5">
      <Dialog
        onConfirm={() => {}}
        isOpen={!!itemToDeleteId}
        setIsOpen={setItemToDeleteId}
        dialogText={"Are you sure you want to delete this journal option?"}
      />

      <AdminEditJournalModal
        itemId={itemToEditId}
        onClose={() => setItemToEditId(null)}
        onSuccessSubmit={() => {}}
      />

      <AdminCreateJournalModal
        isOpen={isCreateDealModalOpen}
        onClose={() => setIsCreateDealModalOpen(false)}
        onSuccessSubmit={() => {}}
      />
      <MDBRow className="mb-2">
        <MDBCol className="text-end">
          <MDBBtn
            size="md"
            onClick={() => setIsCreateDealModalOpen(true)}
            disabled={false}
          >
            <MDBIcon icon="plus-circle" className="me-2" />
            New journal
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <DataTable
        columns={[
          {
            label: "ID",
            field: "id",
          },
          {
            label: "Title",
            field: "title",
          },
          {
            label: "ISSN",
            field: "issn",
          },
          {
            label: "Publisher",
            field: "publisher",
          },
          {
            label: "",
            field: "editAction",
          },
          {
            label: "",
            field: "deleteAction",
          },
        ]}
        rows={[
          {
            id: 6,
            title: "Journal of ufology",
            issn: "2516-8290",
            publisher: "Microbiology Society",
            editAction: (
              <div key={2} onClick={(e) => e.stopPropagation()}>
                <MDBBtn floating onClick={() => setItemToEditId(2)}>
                  <MDBIcon icon="edit" />
                </MDBBtn>
              </div>
            ),
            deleteAction: (
              <div key={1} onClick={(e) => e.stopPropagation()}>
                <MDBBtn
                  color="danger"
                  floating
                  onClick={() => setItemToDeleteId(2)}
                >
                  <MDBIcon icon="trash-alt" />
                </MDBBtn>
              </div>
            ),
          },
          {
            id: 4,
            title: "Questions of oncology",
            issn: "1466-5034",
            publisher: "Microbiology Society",
            editAction: (
              <div key={2} onClick={(e) => e.stopPropagation()}>
                <MDBBtn floating onClick={() => setItemToEditId(2)}>
                  <MDBIcon icon="edit" />
                </MDBBtn>
              </div>
            ),
            deleteAction: (
              <div key={1} onClick={(e) => e.stopPropagation()}>
                <MDBBtn
                  color="danger"
                  floating
                  onClick={() => setItemToDeleteId(2)}
                >
                  <MDBIcon icon="trash-alt" />
                </MDBBtn>
              </div>
            ),
          },

          {
            id: 7,
            title: "Astronavigation discussions",
            issn: "2053-3721",
            publisher: "Microbiology Society",
            editAction: (
              <div key={2} onClick={(e) => e.stopPropagation()}>
                <MDBBtn floating onClick={() => setItemToEditId(2)}>
                  <MDBIcon icon="edit" />
                </MDBBtn>
              </div>
            ),
            deleteAction: (
              <div key={1} onClick={(e) => e.stopPropagation()}>
                <MDBBtn
                  color="danger"
                  floating
                  onClick={() => setItemToDeleteId(2)}
                >
                  <MDBIcon icon="trash-alt" />
                </MDBBtn>
              </div>
            ),
          },
          {
            id: 2,
            title: "Biology discoveries",
            issn: "1465-2099",
            publisher: "Microbiology Society",
            editAction: (
              <div key={2} onClick={(e) => e.stopPropagation()}>
                <MDBBtn floating onClick={() => setItemToEditId(2)}>
                  <MDBIcon icon="edit" />
                </MDBBtn>
              </div>
            ),
            deleteAction: (
              <div key={1} onClick={(e) => e.stopPropagation()}>
                <MDBBtn
                  color="danger"
                  floating
                  onClick={() => setItemToDeleteId(2)}
                >
                  <MDBIcon icon="trash-alt" />
                </MDBBtn>
              </div>
            ),
          },
          {
            id: 3,
            title: "Random letters",
            issn: "1473-5644",
            publisher: "Microbiology Society",
            editAction: (
              <div key={2} onClick={(e) => e.stopPropagation()}>
                <MDBBtn floating onClick={() => setItemToEditId(2)}>
                  <MDBIcon icon="edit" />
                </MDBBtn>
              </div>
            ),
            deleteAction: (
              <div key={1} onClick={(e) => e.stopPropagation()}>
                <MDBBtn
                  color="danger"
                  floating
                  onClick={() => setItemToDeleteId(2)}
                >
                  <MDBIcon icon="trash-alt" />
                </MDBBtn>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default AdminJournalsList;
