import { publisher_id } from "../constants";
import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";
import { articles } from "./mocks/articles";
import { articleDetails } from "./mocks/articleDetails";
import { journals } from "./mocks/journals";
import { NoPidArticles } from "./mocks/noPidArticles";

export async function getArticles(
  searchValue,
  offset,
  perPage,
  sortBy,
  sortDir,
  selection,
  filterValue = "",
  ror = ""
) {
  // return await axiosInstance
  //     .get(`trs/tracker/articles?query=${searchValue}&startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}&selection=${selection}&issn=${filterValue}`)
  //     .catch(handleErrors)
  const filteredArticles = filterValue
    ? articles.articles.filter((item) => item.issn === filterValue)
    : articles.articles;
  const searchArticles = filteredArticles.filter((item) =>
    item.manuscript_title.includes(searchValue)
  );

  const rorFilteredArticles = ror
    ? searchArticles.filter((x) => x.ror === ror)
    : searchArticles;
  const res = {
    total: rorFilteredArticles.length,
    articles: rorFilteredArticles,
  };

  return Promise.resolve({ data: res });
}

export async function getArticleDetails(selectedMessage) {
  // return await axiosInstance
  //     .get(`trs/tracker/article/${selectedMessage}`)
  //     .catch(handleErrors)
  return Promise.resolve({ data: articleDetails });
}

export async function updateArticleDetails(selectedMessage, articleDetails) {
  // return await axiosInstance
  //     .put(`trs/tracker/article/${selectedMessage}`, { articleDetails })
  //     .catch(handleErrors)
  return Promise.resolve();
}

export async function getJournals() {
  // return await axiosInstance
  //     .get(`/v1/journal/publisher/${publisher_id}`)
  //     .catch(handleErrors)
  return Promise.resolve({ data: journals });
}

export async function getNoPidArticles() {
  // return await axiosInstance
  //     .get(`/v1/journal/publisher/${publisher_id}`)
  //     .catch(handleErrors)
  return Promise.resolve({ data: NoPidArticles });
}
