import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./pages/login/Login";
import Pipeline from "./pages/pipeline/Pipeline";
import { Navbar, token } from "pubtrack-frontend-library";
import Payoff from "./components/Payoff";
import Published from "./pages/published/Published";
import Institutions from "./pages/institutions/Institutions";
import Enrichment from "./pages/enrichment/Enrichment";
import { findFirstItem } from "pubtrack-frontend-library";
import { userSettings } from "./user-settings";
import Deals from "./pages/deals/Deals";
import AdminDeals from "./pages/deals/AdminDeals";
import AdminJournals from "./pages/admin-journals-list/AdminJournalsList";
import Funders from "./pages/funders/Funders";
import Eligibility from "./pages/eligibility/Eligibility";
import Messages from "./pages/messages/Messages";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authorized = localStorage.getItem(token);

  const [isAdminMode, setIsAdminMode] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isAdminMode) {
      history.push("/admin-deals");
    }
    if (!isAdminMode) {
      history.push("/pipeline");
    }
  }, [isAdminMode]);

  return (
    <Route
      {...rest}
      render={(props) =>
        authorized ? (
          <>
            <Payoff />
            <Navbar
              {...props}
              navItems={
                isAdminMode ? userSettings.adminNavbar : userSettings.navbar
              }
              isAdminSwitchEnabled={true}
              isAdminMode={isAdminMode}
              setIsAdminMode={setIsAdminMode}
            />
            <Component {...props} />
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <PrivateRoute path="/pipeline" component={Pipeline} />
      <PrivateRoute path="/eligibility" component={Eligibility} />
      <PrivateRoute path="/published" component={Published} />
      <PrivateRoute path="/messages" component={Messages} />
      <PrivateRoute path="/institutions" component={Institutions} />
      <PrivateRoute path="/funders" component={Funders} />
      <PrivateRoute exact path="/deals" component={Deals} />
      <PrivateRoute exact path="/admin-deals" component={AdminDeals} />
      <PrivateRoute exact path="/admin-journals" component={AdminJournals} />
      <PrivateRoute path="/enrichment" component={Enrichment} />
      <Redirect
        exact
        from="/"
        to={findFirstItem(userSettings.navbar, "path")}
      />
    </Switch>
  );
};

export default Routes;
