import React, { useEffect, useState } from "react";
import { MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { useFormik } from "formik";
import { FormModalWrapper } from "../../components/FormModalWrapper";

const AdminEditJournalModal = ({ onClose, itemId, onSuccessSubmit }) => {
  const isOpen = !!itemId;
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      issn: "",
    },
    onSubmit: async (values) => {
      try {
        // Handle the submission
        handleCloseModal();
        await onSuccessSubmit();
      } catch (error) {}
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        // Simulate API call
        // formik.setValues({ title: "Astronavigation discussions", issn: "2053-3721" });
      } catch (error) {
        handleCloseModal();
      } finally {
        setIsLoading(false);
      }
    };

    if (itemId) {
      fetchData();
    }
  }, [itemId]);

  return (
    <FormModalWrapper
      title="Edit Journal"
      isOpen={isOpen}
      isLoading={isLoading || formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBInput
            id="title"
            label="Title"
            {...formik.getFieldProps("title")}
          />
          {formik.errors.title ? (
            <div className="text-danger">{formik.errors.title}</div>
          ) : null}
        </MDBCol>
        <MDBCol md={6}>
          <MDBInput id="issn" label="ISSN" {...formik.getFieldProps("issn")} />
          {formik.errors.issn ? (
            <div className="text-danger">{formik.errors.issn}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
    </FormModalWrapper>
  );
};

export default AdminEditJournalModal;
