import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";

const Dialog = ({
  isOpen,
  setIsOpen,
  onConfirm,
  dialogText,
  dialogTitle = "",
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = async () => {
    try {
      setIsSubmitting(true);
      await onConfirm();
      handleClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => setIsOpen(false);

  return (
    <MDBModal staticBackdrop show={isOpen} tabIndex="-1">
      <MDBModalDialog className="modal-md">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{dialogTitle}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={handleClose}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody className="px-4">{dialogText}</MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={handleClose}>
              Cancel
            </MDBBtn>
            <MDBBtn color="primary" onClick={handleConfirm}>
              Confirm
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default Dialog;
