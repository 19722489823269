import React from "react";
import { MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { useFormik } from "formik";
import { FormModalWrapper } from "../../components/FormModalWrapper";

const AdminCreateJournalModal = ({ onClose, isOpen, onSuccessSubmit }) => {
  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      issn: "",
    },
    onSubmit: async (values) => {
      try {
        // Handle the submission
        handleCloseModal();
        await onSuccessSubmit();
      } catch (error) {}
    },
  });

  return (
    <FormModalWrapper
      title="Create Journal"
      isOpen={isOpen}
      isLoading={formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBInput
            id="title"
            label="Title"
            {...formik.getFieldProps("title")}
          />
          {formik.errors.title ? (
            <div className="text-danger">{formik.errors.title}</div>
          ) : null}
        </MDBCol>
        <MDBCol md={6}>
          <MDBInput id="issn" label="ISSN" {...formik.getFieldProps("issn")} />
          {formik.errors.issn ? (
            <div className="text-danger">{formik.errors.issn}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
    </FormModalWrapper>
  );
};

export default AdminCreateJournalModal;
