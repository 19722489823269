import React from "react";
import { MDBBtn, MDBModalFooter } from "mdb-react-ui-kit";
import { ModalWrapper } from "./ModalWrapper";

const FormModalWrapper = ({
  isLoading,
  isOpen,
  onClose,
  title,
  onSubmit,
  submitButtonTitle = "Submit",
  children,
}) => {
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <ModalWrapper
      title={title}
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={handleCloseModal}
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await onSubmit();
        }}
      >
        {children}
        <MDBModalFooter>
          <MDBBtn type="submit" color="primary">
            {submitButtonTitle}
          </MDBBtn>
        </MDBModalFooter>
      </form>
    </ModalWrapper>
  );
};

export { FormModalWrapper };
